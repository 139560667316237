<template>
  <div @click.prevent="handleClick" :class="[{ 'cursor': to }, `card border-${variant} border-left-${variant} shadow h-100 py-2`]">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col mr-2">
          <div class="text-xs font-weight-bold text-uppercase mb-1">
            {{ title }}
          </div>
          <div class="h5 mb-0 font-weight-bold text-gray-800">{{ value }}</div>
        </div>
        <div class="col-auto">
          <fa-icon v-if="icon" :icon="['fas', icon]" size="2x" class="color-grey"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, null],
      required: false,
      default: null
    },
    title: {
      type: [String, null],
      required: false,
      default: null
    },
    icon: {
      type: [String, null],
      required: false,
      default: null
    },
    variant: {
      type: [String, null],
      required: false,
      default: ''
    },
    to: {
      type: [Object, Function, null],
      required: false,
      default: null
    }
  },
  methods: {
    handleClick () {
      if (!this.to) {
        return
      }

      if (typeof this.to === 'function') {
        this.to()
        return
      }

      this.$router.push(this.to)
    }
  }
}
</script>
